import React from "react";

const ErrorPage = () => {
    return <section className="error-page">
        <div>
            <div>
                <img src={require("../assets/images/cloud_off.png")} alt="404" />
            </div>
            <h1>Page Load Error</h1>
            <p>The page your are trying to view has encountered an error.</p>
        </div>
    </section>
}

export default ErrorPage;