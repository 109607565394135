import React, { useEffect, useState } from "react";

const PrivacyPolicyPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWidth = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <section className="privacy_policy-page">
      <div className="privacy_policy-top_section">
        {windowWidth < 968 ? (
          <h1>Privacy Policy</h1>
        ) : (
          <button onClick={() => console.log("back")}>BACK</button>
        )}
      </div>
      <div className="privacy_policy-main">
        <section>
          {windowWidth >= 968 && <h1>Privacy Policy</h1>}
          <p className="mb-0">This policy was last modified on August 2, 2022.</p>
          <p>
            This privacy policy (this “Privacy Policy”) describes how we collect, use, and share
            data that we obtain through your use of the Internet sites, applications, email and
            newsletter communications, and online services (the “Site”) operated by, controlled by,
            or affiliated with Armored Kingdom Media, LLC (collectively, “Armored Kingdom ,” “we,”
            “us,” or “our”). If you are a resident of California, please review our [California
            Privacy Policy] for additional disclosures required by California law. If you are a
            resident of the European Union, please review our [EU Privacy Policy]for additional
            information relating to your rights. By using the Site, you agree to the practices
            described in this Privacy Policy and any updates posted here from time to time. To make
            sure you stay informed of all changes, you should check this Privacy Policy
            periodically. Updates will be referenced by the “Last Modified” date shown above.
          </p>
        </section>
        <section>
          <div className="table-of-contents">
            <h2>Table of Contents</h2>
            <ol>
              <li>Collection of Data</li>
              <li>Use of Collected Data</li>
              <li>Third-Party Websites and Services</li>
              <li>Sharing of Collected Data</li>
              <li>Transfer of Collected Data</li>
              <li>Data Security</li>
              <li>Data Retention</li>
              <li>Children and Parents</li>
              <li>Our “Do Not Track” DNT Policy</li>
              <li>Your Data Preferences</li>
              <li>California Privacy Policy</li>
              <li>EU Privacy Policy</li>
              <li>Contact Us</li>
            </ol>
          </div>
          <ol className="top-level-list">
            <li>Collection of Data</li>
            <p className="mb-0">
              We collect data from you in several different ways on the Site, including personal
              data and data about your use of the Site which does not identify you personally.
              Personal data means information, or combination of information, that can reasonably be
              used to identify you personally (such as your name, phone number, address, and email
              address). We collect personal data that you voluntarily provide to us. We collect
              personal data that you voluntarily provide to us (such as when you submit an inquiry),
              including name, email address, and any other data you choose to provide. We
              automatically collect data about your use of the Site. When you access and use the
              Site, our technology and tools may automatically collect and record certain data about
              your use of and interaction with the Site that does not identify you personally (such
              as your IP address, browser type, the make and model of device used to view the Site,
              unique device identifiers, the referring webpage, pages visited, and search terms).
            </p>
            <p>
              We also use various tracking technologies (such as cookies and pixel tags) to collect
              data about your use of and access to the Site. Cookies are small data files stored on
              your hard drive or in device memory by websites you visit, applications you use, or
              advertisements you view and help us improve the Site and your experience on the Site.
              Pixel tags are electronic images that generate a generic notice of a visit to the Site
              and are used in conjunction with cookies to anonymously track activity on the Site by
              a particular browser. Third parties may also automatically collect data about you.
              Some content on the Site may be served by third-party content providers and may also
              use cookies, pixels tags, or other similar tracking technologies to automatically
              collect data about you, both on the Site and throughout the Internet. We do not
              control the technologies of those third parties. Data collection and use by those
              third parties is subject to the privacy policies of those third parties. It is up to
              you to review the terms of use and privacy policies of those third parties.
            </p>
            <li>Use of Collected Data</li>
            <p className="mb-0">
              We use the data we collect to assist in the administration and operation of the Site
              and to provide you an efficient, meaningful, and customized experience. We may use
              data to:
            </p>
            <ul className="nested-list list-style-circle">
              <li>
                Fulfill our obligations with respect to the reason you voluntarily provided the data
                (such as responding to an inquiry or provide you with information, products, or
                services that you request from us or that may be of interest to you);
              </li>
              <li>Allow you to participate in certain features of the Site;</li>
              <li>
                Improve the Site or our products, events, and services and for other business or
                commercial purposes;
              </li>
              <li>
                Optimize your experience on the Site (such as troubleshooting technical programs or
                storing your preferences);
              </li>
              <li>
                Provide co-branded services and features (such as contests, sweepstakes, or other
                promotions);
              </li>
              <li>Notify you about changes to the Site (including this Privacy Policy);</li>
              <li>
                Carry out any other purpose described to you at the time the data was collected; and
              </li>
              <li>Carry out any other purpose with your consent or as permitted by law.</li>
              <li>
                Additionally, we may use data for marketing purposes to:
                <ol className="nested-list">
                  <li>
                    Provide you with additional information regarding products, events, promotions,
                    and services from Armored Kingdom and third parties that may be of interest to
                    you via email, text message, or through advertising on various social media
                    platforms or websites (both desktop and mobile) that you may visit; and
                  </li>
                  <li>
                    Develop, improve, and target our promotional messaging to you and others via
                    retargeting, online behavioral advertising, and use of social media platform
                    tools for advertising (such as creation of Facebook Lookalike Audiences).
                  </li>
                  <li>
                    You can learn more about Facebook ads
                    <a
                      href="https://www.facebook.com/ads/about/?entry_product=ad_preferences"
                      target="_blank"
                      rel="noreferrer"
                    >
                      &nbsp;HERE&nbsp;
                    </a>
                    and other types of online advertising
                    <a href="https://youradchoices.com/control" target="_blank" rel="noreferrer">
                      &nbsp;HERE
                    </a>
                    .
                  </li>
                </ol>
              </li>
            </ul>
            <li>Third-Party Websites and Services</li>
            <p>
              Through your use of the Site, you may access, directly or indirectly, other websites
              or services, including from our service providers, business partners, and other third
              parties, in each case, that are not owned or controlled by us. Data collection and use
              by those third parties is subject to the privacy policies of those third parties. It
              is up to you to review the terms of use and privacy policies of those third parties.
              Please note that when you apply for a job with us, the privacy policy of our job
              applicant processing partner also applies to the submission of your application
              information. Please also note that when you sign up to receive text messages from us,
              the privacy policy of our conversation platform partner also applies to the submission
              of your personal information. In addition, you agree to receive recurring text
              messages (which may be marketing and/or automated) from or on behalf of Armored
              Kingdom at the phone number you provided at opt-in. Your consent to receive such text
              messages is not a condition of purchase. Message and data rates may apply and are
              billed by and payable to your mobile service provider. Delivery of messages are
              subject to the effective transmission by your mobile service provider and any policies
              or terms established by your mobile service provider.
            </p>
            <li>Sharing of Collected Data</li>
            <p className="mb-0">We may share the data we collect as follows:</p>
            <ul className="nested-list list-style-circle">
              <li>
                With our current and future parents, affiliates, subsidiaries, and other companies
                under common control and ownership;
              </li>
              <li>
                With our service providers that perform services for us (such as e-mail and mobile
                marketing, analytics, and web hosting);
              </li>
              <li>
                With our sponsors, promotional partners, or event partners in connection with
                certain contests, sweepstakes, promotions, or ticketed events;
              </li>
              <li>
                With our third-party vendors and partners that sell items, offer promotions, or
                provide services to you through our services (either alone or jointly with us);
              </li>
              <li>
                To comply with a court order or other legal obligation, to enforce the Site terms of
                use, and to protect the rights, property, or safety of our users and other third
                parties;
              </li>
              <li>
                With government or law enforcement officials or private parties (including our legal
                or other professional advisors) as we determine, in our sole and absolute
                discretion, is necessary or appropriate to respond to claims or to comply with legal
                processes,laws, regulations, or ordinances, or to prevent or stop illegal,
                unethical, or actionable activity;
              </li>
              <li>
                In connection with, or during negotiations of, any merger,acquisition by another
                company, or sale of all or substantially all of our business or assets; and
              </li>
              <li>With your consent or at your direction.</li>
            </ul>
            <li>Transfer of Collected Data</li>
            <p>
              The Site is operated in the United States. If you are located in another jurisdiction,
              please be aware that the data we collect will be transferred to, stored, and processed
              in the United States. By using the Site and/or providing us with any data, you consent
              to this transfer, processing, and storage of your data in the United States,
              ajurisdiction in which the privacy laws may not be as comprehensive as those in the
              country where you reside or are a citizen such as the European Union. We will take all
              steps reasonably necessary to ensure that your data is treated securely and in
              accordance with this Privacy Notice. We will take all steps reasonably necessary to
              ensure that your data is subject to appropriate safeguards and ensure that your data
              is treated securely and in accordance with this Privacy Policy.
            </p>
            <li>Data Security</li>
            <p>
              We use commercially reasonable technical and organizational measures to help secure
              and safeguard your data in our possession. However, no data transmission over the
              Internet is completely secure and no security mechanism is impenetrable. Therefore,
              although we take steps to protect your data, we cannot guarantee the security of the
              data that we collect from you or the security of our servers or the Site. By accessing
              the Site, any transmission of data is at your own risk and you agree to assume all
              risk in connection with data sent to us or collected by us.
            </p>
            <li>Data Retention</li>
            <p>
              We retain data only as long as necessary in light of the purpose(s) for which it was
              originally collected. Please note that we may retain your data for longer periods of
              time as necessary to comply with our legal obligations or respond to governmental
              authorities.
            </p>
            <li>Children and Parents</li>
            <p>
              We respect children’s privacy. We do not target or knowingly or intentionally collect
              personal data from children under the age of 13. By using the Site, you have
              represented and warranted that you are either at least 18 years of age or using the
              Site with the supervision of a parent or guardian. If you are under the age of 13, you
              may not use the Site. If you become aware that your child has provided us with
              personal data without your consent, please [Contact Us].
            </p>
            <li>Our “Do Not Track” (DNT) Policy</li>
            <p>
              Some websites have “do not track” features that allow you to tell a website not to
              track you. We do not currently respond to those signals or any similar mechanisms
              transmitted by web browsers. To learn more about DNT and tracking signals, please
              visit
              <a href="https://allaboutdnt.com/" target="_blank" rel="noreferrer">
                &nbsp;All About DNT.
              </a>
            </p>
            <li>Your Data Preferences</li>
            <p>
              Use of Collected Data If you no longer want us to use your collected data as described
              above, please submit a request by emailing
              <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com</a>.
              Communications If you no longer wish to receive emails or other communications from
              Armored Kingdom , please follow the instructions in the emails to opt-out from
              receiving such emails in the future or submit a request by emailing
              <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com</a>.
              Cookies, Pixel Tags, and Other Similar Tracking Technologies You may remove or reject
              cookies by adjusting settings on your browser, and thus opt-out of certain features of
              the Site and the Site's automatic collection of certain data. These tracking
              technology opt-out tools are device and browser specific so you must repeat the
              opt-out from each of your devices and browsers. Please note that removing or rejecting
              cookies could affect the functionality of the Site. You may also remove certain
              tracking technologies and opt-out of online behavioral advertising messaging by using
              the opt-out tools available from the Digital Advertising Alliance (
              <a href="https://youradchoices.ca/en/tools" target="_blank" rel="noreferrer">
                DAA Opt-Out Tool
              </a>
              ) and the Network Advertising Initiative (
              <a
                href="https://optout.networkadvertising.org/?c=1#!%252F"
                target="_blank"
                rel="noreferrer"
              >
                NAI Opt-Out Tools
              </a>
              ). Advertising Preferences You may adjust your advertising preferences on various
              social media websites by adjusting your Settings on the particular platform. For
              example, you may adjust the types of Facebook ads you receive on
              <a
                href="https://www.facebook.com/ds/preferences/?entry_product=ad_settings_screen"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Facebook&nbsp;
              </a>
              and the types of Google ads you receive on
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Google
              </a>
              .
            </p>
            <li>California Privacy Policy</li>
            <p>
              If you are a resident of California, please review our for additional disclosures
              required by California law.
            </p>
            <li>EU Privacy Policy</li>
            <p>
              If you are a resident of the European Union, please review our for additional
              information about your rights.
            </p>
            <li>Contact Us</li>
            <p className="mb-0">
              To submit a request relating to Your Data Preferences, please email
              <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com&nbsp;</a>.
              Please note that requests submitted through the form are processed more quickly than
              requests submitted by email or mail. If you have any questions about this Privacy
              Policy, please email us
              <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com&nbsp;</a>or
              write to us at the following address:
            </p>
            <p>
              Armored Kingdom Media , LLC Attention: Legal Department; Privacy Policy Inquiry 9800
              Wilshire Blvd, BH, CA 90212.
            </p>
          </ol>
        </section>
        <section>
          <div className="section-heading">
            <h2>CALIFORNIA PRIVACY POLICY</h2>
            <p>
              This policy was last modified on May 3, 2021. This California Privacy Policy (this
              “California Privacy Policy”) supplements the information contained in the Privacy
              Policy of Armored Kingdom with additional disclosures required by the California
              Consumer Privacy Act (“CCPA”) and other California privacy laws that apply only to
              residents of California. Any terms defined in the Privacy Policy or in the CCPA have
              the same meaning when used in this California Privacy Policy. By using the Site, you
              agree to the practices described in this California Privacy Policy and any updates
              posted here from time to time. To make sure you stay informed of all changes, you
              should check this California Privacy Policy periodically. Updates will be referenced
              by the “Last Modified” date shown above.
            </p>
          </div>
          <div className="table-of-contents">
            <h2>Table of Contents</h2>
            <ol>
              <li>Definition of Personal Information</li>
              <li>Notice of Collection of Personal Information</li>
              <li>Use and Disclosure of Personal Information</li>
              <li>Sale of Personal Information</li>
              <li>Your California Consumer Privacy Rights</li>
              <li>Exercising Your California Privacy Rights</li>
              <li>Contact Us</li>
            </ol>
          </div>
          <ol className="top-level-list">
            <li>
              Definition of Personal Information
              <p>
                As defined in the CCPA, “Personal Information” means information that identifies,
                relates to, describes, is reasonably capable of being associated with, or could
                reasonably be linked, directly or indirectly, with a particular consumer or
                household.
              </p>
            </li>
            <li>
              Notice of Collection of Personal Information
              <p className="mb-4">
                We may collect and may have collected in the last 12 months the following categories
                of Personal Information:
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Examples</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Identifiers</td>
                    <td>
                      A real name, alias, postal address, unique personal identifier, online
                      identifier, Internet Protocol address, email address, account name, Social
                      Security number, driver’s license number, passport number, or other similar
                      identifiers.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Personal information categories listed in the California Customer Records
                      statute (Cal. Civ. Code § 1798.80(e)).
                    </td>
                    <td>
                      A name, signature, Social Security number, physical characteristics or
                      description, address, telephone number, passport number, driver’s license or
                      state identification card number, insurance policy number, education,
                      employment, employment history, bank account number, credit card number, debit
                      card number, or any other financial information, medical information, or
                      health insurance information. Some Personal Information included in this
                      category may overlap with other categories.
                    </td>
                  </tr>
                  <tr>
                    <td>Commercial information</td>
                    <td>
                      Records of personal property, products or services purchased, obtained, or
                      considered, or other purchasing or consuming histories or tendencies.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Online activity; Internet or other electronic network activity information.
                    </td>
                    <td>
                      Browsing history, search history, and information regarding a consumer’s
                      interaction with a website, application, or advertisement.
                    </td>
                  </tr>
                  <tr>
                    <td>Geolocation data</td>
                    <td>Physical location or movements.</td>
                  </tr>
                  <tr>
                    <td>Inferences drawn from any of the information.</td>
                    <td>
                      Profile reflecting a consumer’s preferences, characteristics, psychological
                      trends, predispositions, behavior, attitudes, intelligence, abilities, and
                      aptitudes.
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                Please note that the categories and examples listed above are those defined in the
                CCPA. This does not mean that all examples of that category of Personal Information
                were in fact collected about every Site visitor, but reflects our good faith belief
                to the best of our knowledge that some of that information from the applicable
                category may be and may have been collected.
              </p>
              <p className="mb-0">
                We collect the categories of Personal Information listed above from the following
                categories of sources:
              </p>
              <ol className="nested-list">
                <li>Directly from you when you provide it to us.</li>
                <li>
                  Indirectly and automatically from your devices. For example, when you visit or
                  interact with the Site.
                </li>
                <li>
                  Directly from our parents, affiliates, subsidiaries, and other companies under
                  common control and ownership.
                </li>
                <li>
                  Directly from our clients or their agents. For example, from documents that our
                  clients provide to us related to the services for which they engage us.
                </li>
                <li>From vendors who provide services to us.</li>
                <li>
                  From other third parties. For example, sponsors or event partners in connection
                  with certain ticketed events, social networking providers, and advertising
                  companies. If you do not want us to collect information from social networks, you
                  should review and adjust your privacy settings on those networks as desired before
                  linking or connecting them to the Site.
                </li>
              </ol>
            </li>
            <li className="top-level-list">
              Use and Disclosure of Personal Information
              <p className="mb-4">
                We may use and disclose the Personal Information as described in our [Privacy
                Policy], in Sections 2 and 4, respectively, for business and commercial purposes.
                Additionally, we may use or disclose and may have used or disclosed in the last 12
                months the following categories of Personal Information for business or commercial
                purposes:
              </p>
              <ol className="nested-list">
                <li>Identifiers.</li>
                <li>
                  Personal information categories listed in the California Customer Records statute
                  (Cal. Civ. Code § 1798.80(e)).
                </li>
                <li>Commercial information.</li>
                <li>Online activity; Internet or other electronic network activity information.</li>
                <li>Geolocation data.</li>
                <li>Inferences drawn from any of the information.</li>
              </ol>
            </li>
            <li>
              Sale of Personal Information
              <p className="mb-0">
                We may sell the Personal Information in connection with certain uses and disclosures
                as described in our [Privacy Policy] in Sections 2 and 4, respectively. We may sell
                and may have sold in the last 12 months the following categories of Personal
                Information:
              </p>
              <ol className="nested-list">
                <li>Identifiers.</li>
                <li>
                  Personal information categories listed in the California Customer Records statute
                  (Cal. Civ. Code § 1798.80(e)).
                </li>
                <li>Commercial information.</li>
                <li>Online activity; Internet or other electronic network activity information.</li>
                <li>Geolocation data.</li>
                <li>Inferences drawn from any of the information.</li>
              </ol>
              <p>
                As defined in the CCPA, “sell” and “sale” mean selling, renting, releasing,
                disclosing, disseminating, making available, transferring, or otherwise
                communicating orally, in writing, or by electronic or other means, a consumer’s
                Personal Information by the business to a third party for valuable consideration.
                This means that we may have received some kind of benefit in return for sharing
                Personal Information, but not necessarily a monetary benefit. Please note that the
                categories listed above are those defined in the CCPA. This does not mean that all
                examples of that category of Personal Information were in fact sold, but reflects
                our good faith belief to the best of our knowledge that some of that information
                from the applicable category may be and may have been shared for value in return.
              </p>
            </li>
            <li>
              Your California Consumer Privacy Rights
              <p>
                The CCPA provides residents of California with the following rights regarding their
                Personal Information: Right to Know About Personal Information Collected or
                Disclosed You have the right to request, twice in a 12-month period, that we
                disclose certain information to you about our collection, use, and disclosure of
                your Personal Information over the last 12 months. Once we receive and confirm a
                verifiable consumer request from you, we will disclose to you within the time
                required by the CCPA, the relevant information. Right to Request Deletion of
                Personal InformationYou have the right to request that we delete any of your
                Personal Information that we collected from you and retain, subject to certain
                exceptions. Once we receive and confirm a verifiable consumer request from you, we
                will delete (and direct our service providers to delete) your Personal Information
                from our records within the time required by the CCPA, unless an exception applies.
                Right to Opt-Out of the Sale of Personal InformationYou have the right to opt-out of
                the sale of your Personal Information. Once we receive and confirm a verifiable
                consumer request from you, we will stop selling your Personal Information. Please
                submit a request through our
                <a href="https://forms.gle/7Debpq2dwjN3Uhjn9" target="_blank" rel="noreferrer">
                  &nbsp;Do Not Sell web form.&nbsp;
                </a>
                Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights You have
                the right not to receive discriminatory treatment by us for the exercise of any your
                CCPA rights. We will not discriminate against you for exercising any of your CCPA
                rights.
              </p>
            </li>
            <li>
              Exercising Your California Privacy Rights
              <p>
                To exercise any of the rights described above, please submit a request by emailing
                <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com&nbsp;</a>.
                Verification of Requests When you submit your request, we will reasonably verify
                your identity prior to processing your request to access or delete any Personal
                Information we may hold about you. You must provide sufficient information that
                allows us to reasonably verify you are the person about whom we collected Personal
                Information or an authorized representative of that person. We cannot respond to
                your request or provide you with Personal Information if we cannot verify your
                identity or authority to make the request and confirm the Personal Information
                relates to you. Making a request does not require you to create an account with us.
                We will only use Personal Information provided in a request to verify the
                requestor’s identity or authority to make the request. Making a Request through an
                Authorized Agent You may submit a request through an authorized agent. The agent
                will need to state that they are acting on your behalf when making the request, have
                proof of the authority to act onyour behalf, and be prepared to provide sufficient
                Personal Information to enable us to identify you in our records.
              </p>
            </li>
            <li>
              Contact Us
              <p>
                To submit a request relating to our California Privacy Rights, please email
                <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com&nbsp;</a>.
                If you have any questions about this California Privacy Policy, please email us at
                <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com&nbsp;</a>
                or write to us at:
              </p>
              <p>
                Armored Kingdom Media, LLC Attention: Legal Department; California Privacy Policy
                Inquiry 9800 Wilshire Blvd, BH, CA 90212
              </p>
            </li>
          </ol>
        </section>
        <section>
          <div className="section-heading">
            <h2>EU PRIVACY POLICY</h2>
            <p>
              This policy was last modified on May 3, 2021. This EU Privacy Policy (this “EU Privacy
              Policy”) supplements the information contained in the Privacy Policy of Armored
              Kingdom with additional information relating to your rights under the General Data
              Protection Regulation (“GDPR”) that apply only to residents of the European Union. Any
              terms defined in the Privacy Policy or in the GDPR have the same meaning when used in
              this EU Privacy Policy. By using the Site, you agree to the practices described in
              this EU Privacy Policy and any updates posted here from time to time. To make sure you
              stay informed of all changes, you should check this EU Privacy Policy periodically.
              Updates will be referenced by the “Last Modified” date shown above. We will only
              collect and process your personal data in accordance with applicable data protection
              and privacy laws. The data controller for the personal data that you provide or that
              is collected by Armored Kingdom or its affiliates is Armored Kingdom , LLC, 9800
              Wilshire Blvd, BH, CA 90212.
            </p>
          </div>
          <div className="table-of-contents">
            <h2>Table of Contents</h2>
            <ol>
              <li>Your GDPR Rights</li>
              <li>Legal Basis for Processing Personal Data</li>
              <li>Cookies, Pixel Tags, and Other Similar Tracking Technologies</li>
              <li>Contact Us</li>
            </ol>
          </div>
          <ol className="top-level-list">
            <li>
              Your GDPR
              <p>
                Rights The GDPR provides residents of the EU with the following rights regarding
                their personal data:
              </p>
              <ul className="nested-list list-style-circle">
                <li>Right to be informed about the collection and use of your personal data</li>
                <li>Right to access your personal data</li>
                <li>Right to have any inaccurate personal data corrected</li>
                <li>Right to have your personal data erased</li>
                <li>Right to restrict use of your personal data</li>
                <li>Right to personal data portability</li>
                <li>Right to object to the processing of your personal data</li>
                <li>Right to object to automated decision making</li>
              </ul>
              <p>
                To exercise any of these rights, please submit a request by completing this
                <a href="https://forms.gle/7Debpq2dwjN3Uhjn9" target="_blank" rel="noreferrer">
                  &nbsp;form
                </a>
                . We will respond to any requests within the time required by the GDPR. After you
                submit your request, you will be sent a confirmation email and need to click on the
                link provided to confirm your request. Please note that we may need additional
                information from you to verify your identity before we process your request.
              </p>
            </li>
            <li>
              Legal Basis for Processing Personal Data
              <p className="mb-0">
                To the extent you provide us with personal data, we are processing your personal
                data under one of the following lawful bases:
              </p>
              <ul className="nested-list list-style-circle">
                <li>
                  Consent. By opting-in, you consent to permit us to process your personal data for
                  the purposes set forth in our Privacy Policy.
                </li>
                <li>
                  Compliance with Legal Obligations. We may process your personal data in order to
                  comply with certain of our legal obligations.
                </li>
              </ul>
            </li>
            <li>
              Cookies, Pixel Tags, and Other Similar Tracking Technologies
              <p>
                To the extent required by applicable law, we will obtain your consent before
                collecting data using cookies, pixel tags, and other similar tracking technologies
                on the Site. If you have accepted our use of cookies, pixel tags, and other tracking
                technologies, we will collect your data in accordance with our Privacy Policy based
                on your affirmative informed consent, which you may withdraw through the methods
                provided herein. If you have not accepted, then we only collect your personal data
                based on our legitimate interests. To view additional information about behavioral
                advertising and manage your preferences, you can do so by visiting
                <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer">
                  &nbsp;Your Online Choices
                </a>
                .
              </p>
              <ul className="nested-list list-style-circle">
                <li>
                  Consent. By opting-in, you consent to permit us to process your personal data for
                  the purposes set forth in our Privacy Policy.
                </li>
                <li>
                  Compliance with Legal Obligations. We may process your personal data in order to
                  comply with certain of our legal obligations.
                </li>
              </ul>
            </li>
            <li>
              Contact Us
              <p className="mb-0">
                To submit a request to exercise Your Rights, please email
                <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com&nbsp;</a>
                If you have any questions about this EU Privacy Policy, please email us at
                <a href="mailto:press@armoredkingdom.com ">&nbsp;press@armoredkingdom.com&nbsp;</a>
                or write to us at one of the following addresses:
              </p>
              <p>
                Armored Kingdom Media, LLC Attention: Legal Department; EU Privacy Policy 9800
                Wilshire Blvd, BH, CA 90212
              </p>
            </li>
          </ol>
        </section>
      </div>
    </section>
  );
};

export default PrivacyPolicyPage;
