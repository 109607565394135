import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Founders = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    paragraph: "THE TEAM BEHIND THE KINGDOM ",
    subparagraph: "Bringing together the best in Comics, Gaming & Entertainment"
  };

  return (
    <section id="team" {...props} className={outerClasses}>
      <div className="features-split-middle">
        <div className="container-sm">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className="split-item mb-64">
              <div
                className={classNames(
                  "split-item-image reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/sections/founders/image5.png")}
                  alt="Features split 01"
                  width={140}
                  height={140}
                />
              </div>
              <div className="split-item-divider"></div>
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h4 className="fw-600 tt-u mb-8">Sharad Devarajan</h4>
                <h5 className="mt-0 mb-12">PRODUCER</h5>
                <p className="m-0">
                  “As massive comic book and animation addicts, this is a dream project for all of us at Graphic India and Armored Kingdom has all the elements of a great fantasy meets sci-fi epic that will allow our community to create and participate in the formation of a new universe of heroes, legends, characters and stories.”
                </p>     
              </div>
            </div>
            <div className="split-item mb-64">
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h4 className="fw-600 tt-u mb-8">MILA KUNIS</h4>
                <h5 className="mt-0 mb-12">PRODUCER</h5>
                <p className="m-0">
                  “It was this narrative of Armored Kingdom that sold me. I love
                  Hugh’s imagination and have loved working with him over the
                  years. And on Armored Kingdom, the world, the conflicts, the
                  innovative genre-blending and the wonderful characters you’ll
                  be meeting in future issues… are just awesome. These are
                  aspirational heroes trying to survive in a world where
                  everyone and everything is trying to kill them, desperately
                  trying to save their people by acquiring more epic shit. The
                  cuter the better.”
                </p>
              </div>
              <div className="split-item-divider"></div>
              <div
                className={classNames(
                  "split-item-image reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/sections/founders/image2.png")}
                  alt="Features split 01"
                  width={140}
                  height={140}
                />
              </div>
            </div>
            <div className="split-item mb-64">
              <div
                className={classNames(
                  "split-item-image reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/sections/founders/image6.png")}
                  alt="Features split 01"
                  width={140}
                  height={140}
                />
              </div>
              <div className="split-item-divider"></div>
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h4 className="fw-600 tt-u mb-8">Lindsey McInerney</h4>
                <h5 className="mt-0 mb-12">PRODUCER</h5>
                <p className="m-0">
                  “To be able to start with a story and universe and bring technology in as an additive layer is one of the most exciting things I've had the chance to work on. I can't wait to bring people into this fantasy meets sci-fi world and allow them to play and own a piece of it.”
                </p>
              </div>
            </div>
            <div className="split-item mb-64">
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h4 className="fw-600 tt-u mb-8">HUGH STERBAKOV</h4>
                <h5 className="mt-0 mb-12">CREATOR</h5>
                <p className="m-0">
                  “I couldn’t possibly be more excited to finally share Armored
                  Kingdom with you. This book is just an introduction to a wide
                  world of narrative and interactive storytelling to come—an
                  inciting incident that’ll spark a generation’s worth of
                  conflict and mystery. We’re working on several multimedia
                  fronts to develop the story, all under the same creative team,
                  all connected and serialized and—yes, I promise—we have an
                  endgame in mind.”
                </p>
              </div>
              <div className="split-item-divider"></div>
              <div
                className={classNames(
                  "split-item-image reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/sections/founders/image1.png")}
                  alt="Features split 01"
                  width={140}
                  height={140}
                />
              </div>
            </div>
            <div className="split-item mb-64">
              <div
                className={classNames(
                  "split-item-image reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/sections/founders/image4.png")}
                  alt="Features split 01"
                  width={140}
                  height={140}
                />
              </div>
              <div className="split-item-divider"></div>
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h4 className="fw-600 tt-u mb-8">Lisa Sterbakov</h4>
                <h5 className="mt-0 mb-12">PRODUCER</h5>
                <p className="m-0">
                  “There is nothing that drives the feeling of community as much as sharing stories and playing games. In our increasingly isolated world, we are excited to be working on something that can bring people together to read, watch, and play. And the blockchain allowing players to actually own their digital game pieces, is a massive bonus for avid gamers like me.”
                </p>
              </div>
            </div>
            <div className="split-item mb-24">
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h4 className="fw-600 tt-u mb-8">BRIAN TURNER</h4>
                <h5 className="mt-0 mb-12">CO-WRITER AND GAME DESIGNER</h5>
                <p className="m-0">
                  “When Hugh came to me for help with this project, I was more
                  than ready to jump right in. What do we need? Lists of
                  weapons? Done. Lists of armors? Done. Different kinds of
                  magical enchantments? Do you want that sorted by school of
                  magic or by overall power? But as we started working on the
                  game mechanics, we realized that NFTs offered much more
                  potential to really shake things up.”
                </p>
              </div>
              <div className="split-item-divider"></div>
              <div
                className={classNames(
                  "split-item-image reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/sections/founders/image3.png")}
                  alt="Features split 01"
                  width={140}
                  height={140}
                />
              </div>
            </div>
          </div>
        </div>
        <Image
          id="armorite31"
          style={{ transform: `translateY(${-offsetY*0.5}px)` }}
          className="armorite"
          src={require("./../../assets/images/sections/founders/Armorite.png")}
        />
      </div>
    </section>
  );
};

Founders.propTypes = propTypes;
Founders.defaultProps = defaultProps;

export default Founders;
