import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Partnerlogo = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "partnerlogo section mt-64",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "partnerlogo section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    paragraph: "Partners",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content m-0 reveal-from-bottom"
            data-reveal-delay="400"
            tag="h2"
          />
          <div className="logos">
            <Image
              alt={"Near Logo"}
              src={require("../../assets/images/sections/partnerlogos/logo1.png")}
            />
            <Image
              alt={"Graphic India Logo"}
              src={require("../../assets/images/sections/partnerlogos/logo2.png")}
            />
            <Image
              alt={"OFP Logo"}
              src={require("../../assets/images/sections/partnerlogos/logo3.png")}
            />
             <Image
              alt={"Liquid Comics Logo"}
              src={require("../../assets/images/sections/partnerlogos/logo4.png")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Partnerlogo.propTypes = propTypes;
Partnerlogo.defaultProps = defaultProps;

export default Partnerlogo;
