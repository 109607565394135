import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Input from '../elements/Input';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import LoadingScreen from '../elements/LoadingScreen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [mintPopup, setMintPopup] = useState(false);
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [nftMsg, setNftMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const reCaptchaRef = useRef();
  const recaptchaSiteKey = '6Lf7lXsgAAAAAK34WnPfX30hw78U4IUiR6RLXZ-G';

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNftMsg(null);

    if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setNftMsg('Please enter a valid email');
      return;
    }

    if (!address || address == '') {
      setNftMsg('Please enter a NEAR address');
      return;
    }

    const token = await reCaptchaRef.current.executeAsync();

    setLoading(true);
    axios({
      url: process.env.REACT_APP_BACKEND_URL + 'linkdrop/api/emailNftUrl/',
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      data: {
        email: email,
        address: address,
        recaptchaToken: token,
      },
    })
      .then((res) => {
        toast.success('Email saved', {
          position: 'bottom-right',
          autoClose: 5000,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.msg, {
          position: 'bottom-right',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
        reCaptchaRef.current.reset();
        setMintPopup(false);
      });
  };

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  // handle /#claim scroll on load
  // timeout to handle element resizing on page load, after loading is cleared
  const claimRef = useRef();
  useEffect(() => {
    if (window.location.hash === '#claim' && claimRef.current) {
      const timeout = setTimeout(
        () => claimRef.current.scrollIntoView({ behavior: 'smooth' }),
        500
      );
      return () => clearTimeout(timeout);
    }
  }, [claimRef]);

  return (
    <>
      <section ref={claimRef} id="claim" {...props} className={outerClasses}>
        <Swiper
          slidesPerView="auto"
          loop
          centeredSlides
          navigation
          className="container-fluid hero-top"
          modules={[Navigation]}
        >
          <SwiperSlide>
            <div className={innerClasses}>
              <div className="hero-carousel">
                <div className="comic-dropdown reveal-from-bottom">
                  <h3>COMIC ISSUE: 0</h3>
                </div>
                <div className="hero-carousel-item">
                  <div className="hero-carousel-item-inner">
                    <div className="title reveal-from-bottom">
                      <h3>READ</h3>
                      <h1>ISSUE #0</h1>
                    </div>
                    <Image
                      className="comic-book-cover"
                      src={require('./../../assets/images/sections/hero/ComicBook2.png')}
                      alt="Comic Book Cover"
                    />
                    <div
                      className="copy reveal-from-bottom"
                      data-reveal-delay="400"
                    >
                      <p>
                        Get introduced to the world of{' '}
                        <span>Armored Kingdom</span> with issue 0 of our first
                        in the comic series. 6 levels of rarity. Mint your own
                        tradable comic NFT now.
                      </p>
                      <a
                        href="https://armoredkingdom.com/comic/issue-0"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read now
                      </a>
                    </div>
                    <div className="lightray"></div>
                  </div>
                  <div className="mint-now">
                    <h3>Get your limited edition nft cover</h3>
                    <button>Mint closed</button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={innerClasses}>
              <div className="hero-carousel">
                <div className="comic-dropdown reveal-from-bottom">
                  <h3>COMIC ISSUE: 1</h3>
                </div>
                <div className="hero-carousel-item">
                  <div className="hero-carousel-item-inner">
                    <div className="title reveal-from-bottom">
                      <h3>READ</h3>
                      <h1>ISSUE #1</h1>
                    </div>
                    <Image
                      className="comic-book-cover"
                      src={require('./../../assets/images/sections/hero/issue1cover-crop.png')}
                      alt="Comic Book Cover"
                    />
                    <div
                      className="copy reveal-from-bottom"
                      data-reveal-delay="400"
                    >
                      <p>
                        Now that you've entered the world of <span>Armored Kingdom</span> with Issue 0, continue on the journey and delve into Issue 1. Two levels of rarity are available when mint is open.
                      </p>
                      <a
                        href="https://armoredkingdom.com/comic/issue-1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read now
                      </a>
                    </div>
                    <div className="lightray"></div>
                  </div>
                  <div className="mint-now">
                    <h3>Get your limited edition nft cover</h3>
                    <button>Mint closed</button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="container-fluid hero-bottom">
          <div className={innerClasses}>
            <div
              className="hero-figure reveal-from-bottom pt-32 pr-0"
              data-reveal-value="20px"
              data-reveal-delay="200"
            >
              <Image
                className=""
                src={require('./../../assets/images/sections/hero/ComicBook1.png')}
                alt="Sideways Comic Book"
              />
            </div>
            <div
              className="hero-content launch-drop ta-l"
              style={{ marginTop: '0px' }}
            >
              <p className="reveal-from-bottom" data-reveal-delay="200">
                Get updates on our next issue release dates
              </p>
              <div
                className="input-group reveal-from-bottom"
                data-reveal-delay="300"
              >
                <form
                  action="https://www.getrevue.co/profile/armoredkingdom/add_subscriber"
                  method="post"
                  id="revue-form"
                  name="revue-form"
                  target="_blank"
                >
                  <input type="email" placeholder="Enter your email address" className="subscribe-input" />
                  <Button>SUBMIT</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {mintPopup ? (
        <div className="mint-popup">
          <div
            className="close-button"
            onClick={(e) => setMintPopup(false)}
          ></div>
          <div className="content">
            {loading ? (
              <LoadingScreen transparent={true} />
            ) : (
              <>
                <div className="text-content">
                  <div className="title">CLAIM COMIC ISSUE 1 AIRDROP</div>
                  <div className="description">
                    You will be sent an e-mail confirming your airdrop for one
                    comic NFT. Airdrops will periodically occur over the next 14
                    days
                  </div>
                </div>
                <div className="bottom-content">
                  <div className="left-content">
                    <form style={{ flexDirection: 'column' }}>
                      <Input
                        id="member_email"
                        placeholder="Enter your email address"
                        type="email"
                        formGroup="desktop"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Input
                        style={{ marginTop: '10px' }}
                        id="member_address"
                        placeholder="Enter your NEAR address"
                        type="text"
                        formGroup="desktop"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <span className="create-wallet">
                        Don't have NEAR wallet? Create one{' '}
                        <a
                          href="https://wallet.near.org/create"
                          rel="noreferrer"
                          target="_blank"
                        >
                          here
                        </a>
                      </span>
                      <span>{nftMsg}</span>
                      <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        size="invisible"
                        ref={reCaptchaRef}
                        className="recaptcha"
                      />
                      <Button type="submit" onClick={(e) => handleSubmit(e)}>
                        SUBMIT
                      </Button>
                    </form>
                  </div>
                  <div className="right-content">
                    <Image
                      src={require('./../../assets/images/sections/hero/issue1cover.jpg')}
                      alt="Comic Book Cover"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
