import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Button from '../elements/Button';
import axios from "axios";
import { CLoadingButton } from '@coreui/react-pro';



const propTypes = {
    children: PropTypes.node,
    ...SectionProps.types
}

const defaultProps = {
    children: null,
    ...SectionProps.defaults
}

const MintBody = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const nftType = props.type ? props.type : "Common";
    const [state, setState] = useState(false);
    const mintNFT = () => {
        setState(!state);
        axios({
            url: process.env.REACT_APP_BACKEND_URL + "linkdrop/api/getnftbytype/",

            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            data: {
                type: nftType
            }
        }).then((res) => {
            window.location.replace(res.data);
            setState(!state);
        }).catch(err => {
            console.log(err);
            setState(!state);
        });
    }

    const disabledNfts = ['Unlimited', 'Legendary'];

    const outerClasses = classNames(
        'mint-body section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'section-inner mint-body-inner  pb-32',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <div className='pl-64 pr-64 pb-128 pt-64'>
                        <Image
                            className="mint-logo"
                            src={require("./../../assets/images/sections/introducing/Logo.png")}
                            alt="Logo"
                        />
                    </div>
                    <div className='nft-container'>
                        <div className='nft-image pl-64 pr-64'>
                            <Image
                                className="mint-img"

                                src={require(`./../../assets/images/sections/mint/${nftType}.jpg`)}

                                alt="Armorite"
                            />
                        </div>
                        <div className='nft-content'>
                            <h4 className='mb-8'>Comic Issue O</h4>
                            <p>{nftType} Edition NFT</p>
                        </div>
                    </div>

                    <div className='mint-button pt-32'>
                        {
                            (!disabledNfts.includes(props.type)) ?
                                <CLoadingButton
                                    onClick={(e) => {
                                        mintNFT();
                                    }}
                                    loading={state}
                                    className="button button-primary button-wide-mobile button-sm"
                                >
                                    Mint Now
                                </CLoadingButton>
                            :
                                <CLoadingButton
                                    loading={state}
                                    className="button button-primary button-wide-mobile button-sm"
                                >
                                    NO LONGER AVAILABLE
                                </CLoadingButton>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

MintBody.propTypes = propTypes;
MintBody.defaultProps = defaultProps;

export default MintBody;