import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Buffer } from 'buffer';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "@near-wallet-selector/modal-ui/styles.css";
import { WalletSelectorContextProvider } from "./contexts/WalletSelectorContext";

//import './App.css';

import './assets/scss/style.scss';

const history = createBrowserHistory();
// window.nearInitPromise = initContract()
//   .then(() => {
//     ReactDOM.render(
//       <Router history={history}>
//         <App />
//       </Router>,
//       document.getElementById('root')
//     )
//   })
//   .catch(console.error)

ReactDOM.render(
  <Router history={history}>
    <WalletSelectorContextProvider>
      <App />
    </WalletSelectorContextProvider>
  </Router>,
  document.getElementById('root')
)

window.Buffer = Buffer;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
