import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import ImagesMenu from "../elements/ImagesMenu";
import { useWalletSelector } from "../../contexts/WalletSelectorContext";
import { view } from "../../utils/utils";
const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};
const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};
const MyProfile = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [nftResults, setNftResults] = useState([]);
  const [nftResults1, setNftResults1] = useState([]);
  const [imagesMenu, setImagesMenu] = useState(false);
  const [profilePic, setProfilePic] = useState(require('../../assets/images/ak_profile_2x.png'));
  const [imageSelected, setImageSelected] = useState(1);
  const {modal, accountId } = useWalletSelector();
  
  useEffect(() => {
    let pic = localStorage.getItem('profilePic');
    let picNumber = localStorage.getItem('profilePicNumber');
    if(pic) {
      setProfilePic(pic);
      setImageSelected(picNumber);
    }
    
  },[]);
  useEffect(() => {
    if (!showLoader) {
      displayAllNFT();
    }
  }, [showLoader]);
  const displayAllNFT = async () => {
    const result = await view(process.env.REACT_APP_CONTRACT_NAME, "nft_tokens_for_owner", {
        account_id: accountId,
        from_index: "0",
        limit: 64,
    });
    
    setNftResults(result);
    const result1 = await view(process.env.REACT_APP_CONTRACT_NAME_1, "nft_tokens_for_owner", {
      account_id: accountId,
      from_index: "0",
      limit: 64,
    });
    setNftResults1(result1);
  };
  
  const outerClasses = classNames(
    "myprofile section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );
  const innerClasses = classNames(
    "myprofile-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "myprofile-split"
  );
  const sectionHeader = {
    paragraph: "IN MY WALLET",
  };
  const handleImageChange = (image) => {
    setProfilePic(image);
    localStorage.setItem('profilePic', image);
  };
  const handleImageSelected = (number) => {
    setImageSelected(number);
    localStorage.setItem('profilePicNumber', number);
  }
  const copyToClipBoard = () => {
    var range = document.createRange();
    range.selectNode(document.getElementById("wallet-address"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }
  const handleSignIn = () => {
    modal.show();
  };
  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className="account-section">
          <div>
            <div className="profile-image">
              <div>
                <Image src={profilePic} />
                <span onClick={e=>setImagesMenu(!imagesMenu)}>CHANGE<span></span></span>
              </div>
              <div>
                {
                  (accountId) ?
                  <>
                    <span id="wallet-address">{accountId}</span>
                    <span onClick={e=>copyToClipBoard()}>COPY TO CLIPBOARD</span>
                  </>
                  :
                  <button
                    className="button button-primary button-wide-mobile button-sm"
                    onClick={handleSignIn}
                  >
                    CONNECT WALLET
                  </button>
                }
              </div>
            </div>
            {
              imagesMenu ?
              <ImagesMenu
                handleImageChange={handleImageChange}
                imageSelected={imageSelected}
                setImageSelected={handleImageSelected}
                menuToggle={e=>setImagesMenu(!imagesMenu)}
              />
              :null
            }
          </div>
        </div>
        <hr/>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader}/>
          <div className="integrate-item-list">
            {
                nftResults ?
                nftResults.map((nft, index) => (
                  <div>
                    <div className="integrate-item m-8" key={index}>
                        <Image
                            src={nft.metadata.media}
                            width={312}
                            height={280}
                        />
                        <div className="issue-title">
                          <h6>{nft.metadata.title}</h6>
                        </div>
                    </div>
                    <a href={`/comic/${nft.token_id}`}><div className="read-button">READ COMIC</div></a>
                  </div>
                )): ''
            }
          </div>
          <div className="integrate-item-list">
            {
                nftResults1 ?
                nftResults1.map((nft, index) => (
                  <div>
                    <div className="integrate-item m-8" key={index}>
                        <Image
                            src={nft.metadata.media}
                            width={312}
                            height={280}
                        />
                        <div className="issue-title">
                          <h6>{nft.metadata.title}</h6>
                        </div>
                    </div>
                    <a href={`/comic/${nft.token_id}`}><div className="read-button">READ COMIC</div></a>
                  </div>
                )): ''
            }
          </div>
        </div>
      </div>
    </section>
  );
};
MyProfile.propTypes = propTypes;
MyProfile.defaultProps = defaultProps;
export default MyProfile;
