import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import UniverseComponent from "../elements/UniverseComponent";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Universe = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const outerClasses = classNames(
    "universe section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "universe-inner section-inner pt-64 pos-relative",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  return (
    <section {...props} className={outerClasses} id="universe">
      <div className="container-fluid">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <div className="hero-content ta-l pt-200">
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                THE UNIVERSE
              </h1>
              <p
                className="m-0 mb-32 reveal-from-bottom subgraph"
                data-reveal-delay="400"
              >
                After 100 years of war, the six dynastic formed a union.
                Together, they face a mysterious force threatens the Kingdom.
              </p>
              <UniverseComponent
                title="The Veldt"
                content={"Home to the humans, the Veldt is a rich land occupied by happy homes, sprawling farms, and bountiful markets, all under the protection of The Phalanx, the magnificent medieval castle that serves as a nexus for trade across the entire continent."}
                imageUrl="land1.png"
              />
              <UniverseComponent
                title="Vengewood Jungle"
                content={"This thick rainforest is home to the Kingdom of the Horn, anthropomorphized animals whose simple structures dot the base of Mt. Shine, the world’s only source of magical Armorite – a resource they’ve fought over for generations."}
                imageUrl="land2.png"
              />
              <UniverseComponent
                title="The Fathoms"
                content={"The ocean south of Mt. Shine is inhabited by the Costraca, a union of sea-dwelling races who breathe both underwater and on land. They too lay claim to Mt. Shine—known in their ancient scrolls as the Creation Peak—and they’ll stop at nothing to rid their sacred mountain of the filth that is the Horn."}
                imageUrl="land3.png"
              />
              <UniverseComponent
                title="The Hulking Vast"
                content={"Once home to an advanced civilization, this long-abandoned city has now been reclaimed by the ravages of nature. It’s inhabited by the enigmatic Warri, a long-lost splinter of the humans who’ve developed mysterious powers due to their exposure to Armorite dust."}
                imageUrl="land4.png"
              />
              <UniverseComponent
                title="The Ghosted Glades"
                content={"Once the most vibrant region in the world, the Ghosted Glades was the site of the Decimation, a mysterious event that spelled the downfall of the Ancestors. Since then, it’s been pummeled by an eternal tempest known as the Darkstorm. The glades are home to the Autumn, a sorcerer society of outcasts from all other races, but few knew what horrors lurk further within."}
                imageUrl="land5.png"
              />
            </div>

            <div
              className="hero-figure reveal-from-bottom illustration-element-01"
              data-reveal-value="20px"
              data-reveal-delay="800"
            >
              <Image
                className=""
                src={require("./../../assets/images/sections/universe/Map.png")}
                alt="Hero"
                width={896}
                height={504}
              />
            </div>
            <Image
              style={{ transform: `translateY(${-offsetY*0.5}px)` }}
              id="universe-armorite"
              src={require("./../../assets/images/sections/universe/Armorite.png")}
              alt="Hero"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Universe.propTypes = propTypes;
Universe.defaultProps = defaultProps;

export default Universe;
