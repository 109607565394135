import React from "react";
import { useParams } from "react-router-dom"

import MintBody from "../components/sections/MintBody";
import MintDes from "../components/sections/MintDes";

const Mint = () => {

    const { type } = useParams();

    return (
        <>
            <MintBody type={type} />
            <MintDes topOuterDivider={true} />
        </>
    );
};

export default Mint;
