import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Introducing from '../components/sections/Introducing';
import Universe from '../components/sections/Universe';
import Founders from '../components/sections/Founders';
import Dynasties from '../components/sections/Dynasties';
import Integrate from '../components/sections/Integrate';
import Video from '../components/sections/Video';
import Partnerlogo from '../components/sections/Partnerlogo.js';

const Home = () => {
  return (
    <>
      <Introducing />
      <Video />
      <Hero />
      <Universe />
      <Dynasties />
      <Founders />
      <Integrate />
      <Partnerlogo />
    </>
  );
}

export default Home;