import React, { useEffect, useRef, useState } from 'react';
import getConfig from "../utils/config";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import classNames from "classnames";
import Image from '../components/elements/Image';

const { contractName } = getConfig(process.env.REACT_APP_NODE_ENV || "development");
const contractName1 = process.env.REACT_APP_CONTRACT_NAME_1 || "development";

const Comic = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props}) => {
    const CONTRACT_NAME = (props.match.params.contract == 1) ? contractName1 : contractName;
    
    const [width, setWidth] = useState(window.innerWidth);

    const [pageCount, setPageCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [zoomLevel, setZoomLevel] = useState(1);

    const [pdf, setPdf] = useState();
    const [title, setTitle] = useState();
    const [mobileFile, setMobileFile] = useState();

    const outerClasses = classNames(
        "section center-content-mobile reveal-from-bottom",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
      );

    useEffect(() => {
        async function getComic() {
            let tokenData = await window.walletConnection
                .account()
                .viewFunction(CONTRACT_NAME, "nft_token", {
                    token_id: props.match.params.id,
                });

            setPdf(tokenData.metadata.pdf_media);
            setTitle(tokenData.metadata.title);
        }
        //check if first issue
        if(props.match.params.id == 'issue-0') {
            setMobileFile('https://bafybeidkw5yalafhktwhbixroh5ch7aq4vjzhtdyycwtnea3rqpnpdu6hu.ipfs.nftstorage.link/')
            setPdf('https://nftstorage.link/ipfs/bafybeigz4qatlag3pcoy7a5f2powrxsxbtpgnrx35nniag74y6eqq5fxvi');
            setTitle('Issue 0 | Unlimited');
        } else if (props.match.params.id == 'issue-1') {
            setMobileFile('https://bafybeiehsecp7vmson72fdn2ey5fvsju6zpuyymbuthtjqkoxliddmottu.ipfs.nftstorage.link/')
            setPdf('https://bafybeih72yyf5exa3udbnsl7ot6f3d6nmpdw6aovrv3kqou46rhxmlh2na.ipfs.nftstorage.link/');
            setTitle('Issue 1 | Unlimited');
        } else {
            getComic();
        }

    },[]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = width <= 768;

    const onDocumentLoadSuccess = ({numPages}) => {
        setPageCount(numPages);
    };

    const nextPage = () => {
        const atLastPage = currentPage === pageCount;

        if (atLastPage) {
            setCurrentPage(1);
        } else{
            setCurrentPage(currentPage+1)
        }
    }

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage-1)
        }
    };

    const zoomIn = () => {
        if(zoomLevel <=10) setZoomLevel(zoomLevel + 0.5);
    };

    const zoomOut = () => {
        if(zoomLevel > 1) setZoomLevel(zoomLevel - 0.5);
    };

    const mobileView = () => (
        mobileFile
        ? <Image src={mobileFile} alt={title} />
        : [...Array(pageCount)].map((_, idx) => (
            <Page
                key={idx}
                pageNumber={idx + 1}
                className="pdfViewerPage"
                width={width}
            />
        ))
    )

    const desktopView = () => (
        <>
            <Page
                pageNumber={currentPage}
                scale={zoomLevel}
                className="pdfViewerPage"
                width={width < 1024 ? null : 530}
            />
            {
                (width > 1024 && (currentPage+1) <= pageCount) &&
                    <Page
                        pageNumber={currentPage + 1}
                        scale={zoomLevel}
                        className="pdfViewerPage"
                        width={530}
                    />
            }
        </>
    )

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className='controls'>
                    {!isMobile && <button onClick={e=>previousPage()}>Previous</button>}
                    <div>
                        {title}
                        {!isMobile && <span>page {currentPage} of {pageCount}</span>}
                    </div>
                    {!isMobile && <button onClick={e=>nextPage()}>Next</button>}
                </div>
                { pdf && (
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} className="pdfViewerDocument">
                        { isMobile ? mobileView() : desktopView() }
                    </Document>
                    )}

            </div>
        </section>
    );
}

export default Comic;