import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
    children: PropTypes.node,
    ...SectionProps.types
}

const defaultProps = {
    children: null,
    ...SectionProps.defaults
}

const MintDes = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'section mint-description',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'section-inner mint-description-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const taC = classNames(" ta-c");

    const description = classNames(" description");

    return (
        <>
            <section
                {...props}
                className={outerClasses}
            >
                <div className={innerClasses + description}>
                    <div>
                        <p>
                            The comic you've just received comes with a corresponding NFT. We just need to get it to your wallet.
                        </p>

                        <p>
                            When you click on the Mint Now button below, this is what's going to happen:
                        </p>

                        <ol>
                            <li>You will be redirected to the Near blockchain website, where you will either create a new Near wallet or be able to import an existing wallet.</li>
                            <li>The comic NFT will then be minted and dropped into your wallet.</li>
                            <li>Once that’s done, you will be redirected back to the Armored Kingdom website, where you will be able to access and read your comic NFT by connecting your wallet.</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section
                {...props}
                className={outerClasses}
            >
                <div className="container">
                    <div className={innerClasses}>
                        <div className='pl-64 pr-64 dis-flex'>
                            <Image
                                src={require("../../assets/images/sections/partnerlogos/logo1.png")}
                            />
                        </div>

                    </div>
                </div>
            </section>
        </>

    );
}

MintDes.propTypes = propTypes;
MintDes.defaultProps = defaultProps;

export default MintDes;