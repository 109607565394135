import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "./Image";

const propTypes = {
  tag: PropTypes.elementType,
  color: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  wide: PropTypes.bool,
  wideMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  settogglestatus: PropTypes.func,
};

const defaultProps = {
  tag: "Universecomp",
  color: "",
  size: "",
  loading: false,
  wide: false,
  wideMobile: false,
  disabled: false,
  title: "The Phalynx",
  imageUrl: "ArmoredKingdom1.png",
  content:
    "Home to the humans, the Veldt is a rich land occupied by happy homes, sprawling farms, and bountiful markets, all under the protection of The Phalanx, the magnificent medieval castle that serves as a nexus for trade across the entire continent.",
};

const UniverseComponent = ({
  className,
  tag,
  color,
  size,
  loading,
  wide,
  wideMobile,
  disabled,
  title,
  imageUrl,
  content,
  ...props
}) => {
  // const togglestatus = false;

  const arrayDown = "Vector-down.png";
  const arrayUp = "Vector-up.png";
  const [arrayDirection, setArrayDirection] = useState(arrayDown);

  // dropdown
  const [height, setHeight] = useState(0);
  const dropSizeRef = useRef(null);
  const [toggleStatus, setToggleStatus] = useState(false);
  useEffect(() => {
    setHeight(dropSizeRef.current.clientHeight);
  },[]);

  const classes = classNames(
    "universeComponent mb-16",
    color && `universecomponent-${color}`,
    size && `universecomponent-${size}`,
    loading && "is-loading",
    wide && "universecomponent-block",
    wideMobile && "universecomponent-wide-mobile",
    className
  );

  return (
    <div {...props} className={classes} disabled={disabled}>
      <div
        className={`unicomp-body ${toggleStatus ? "transition" : ""}`}
        style={{height: toggleStatus ? `${50 + 15 + height}px` : "50px"}}
      >
        <div className="unicomp-header">
          <div className="unicomp-header-left">
            <Image
              className=""
              src={require("./../../assets/images/sections/universe/components-img/" +
                imageUrl)}
              alt="Armored Kingdom"
              width={50}
              height={50}
            />
            <span>{title}</span>
          </div>
          <div className="unicomp-header-right">
            <Image
              src={require("../../assets/images/sections/universe/components-img/" +
                arrayDirection)}
              width={24}
              height={15}
              onClick={(e) => {
                setToggleStatus(!toggleStatus);
                toggleStatus
                  ? setArrayDirection(arrayDown)
                  : setArrayDirection(arrayUp);
              }}
            />
          </div>
        </div>
        <div
          className="unicomp-content mb-0"
        >
          <div className="text-xs" ref={dropSizeRef}>{content}</div>
        </div>
      </div>
    </div>
  );
};

UniverseComponent.propTypes = propTypes;
UniverseComponent.defaultProps = defaultProps;

export default UniverseComponent;
