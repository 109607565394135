import React from "react";

import MyProfile from "../components/sections/MyProfile";

const Profile = () => {
  return (
    <>
      <MyProfile />
    </>
  );
};

export default Profile;
