import React from 'react';
import Image from "./Image";

const ImagesMenu = ({
    handleImageChange,
    imageSelected,
    setImageSelected,
    menuToggle,
}) => {

    const changePic = (image, number) => {
        handleImageChange(image);
        setImageSelected(number);
        menuToggle();
    };

    const images = [
        {url:require('../../assets/images/costraca.jpg'),imageNumber:'1',name:'Costraca'},
        {url:require('../../assets/images/king-colossus-b.jpg'),imageNumber:'2',name:'King Colossus'},
        {url:require('../../assets/images/rare-gorillakar-d.jpg'),imageNumber:'3',name:'Gorillakar D'},
        {url:require('../../assets/images/rare-gorillakar-a.jpg'),imageNumber:'4',name:'Gorillakar A'},
        {url:require('../../assets/images/fmain.jpg'),imageNumber:'5',name:'FMain'},
        {url:require('../../assets/images/gazelle.jpg'),imageNumber:'6',name:'Gazelle'},
        {url:require('../../assets/images/female-e.jpg'),imageNumber:'7',name:'Female E'},
    ];

    return (
        <div className='images-menu'>
            {
                images.map((image,k) => {
                    return(
                        <div onClick={e=>changePic(image.url,image.imageNumber)}>
                            <div>
                                <Image src={image.url} />
                                <div>{image.name}</div>
                            </div>
                            {
                                (imageSelected === image.imageNumber) ?
                                    <img src={require('../../assets/images/white-checkmark.png')} />
                                :null
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default ImagesMenu;