import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import Image from "../elements/Image";

const propTypes = {};

const defaultProps = {};
const Introducing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames("videosection section", className);
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  const videoRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  function resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }

  return (
    <section id="trailer" {...props} className={outerClasses}>
      <div className="videosection-inner">
          <iframe
            title="Trailer"
            src="https://player.vimeo.com/video/731355686?h=309afc3103"
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            scrolling="no"
            allowFullScreen></iframe>
      </div>
      <Image
        id="video-armorite"
        style={{ transform: `translateY(${-offsetY*0.5}px)` }}
        src={require("./../../assets/images/sections/video/Armorite.png")}
        alt="Hero"
      />
    </section>
  );
};

Introducing.propTypes = propTypes;
Introducing.defaultProps = defaultProps;

export default Introducing;
